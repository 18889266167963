<template>
  <div>
    <rapi-doc
      id="api-docs"
      theme="light"
      render-style="focused"
      allow-spec-url-load="false"
      allow-spec-file-load="false"
      allow-server-selection="false"
      :server-url="serverUrl"
      fill-request-fields-with-example="false"
      allow-authentication="true"
      allow-try="true"
      :spec-url="getSpecUrl"
      allow-spec-file-download="true"
      persist-auth="true"
      show-method-in-nav-bar="as-colored-text"
      use-path-in-nav-bar="true"
      primary-color="#461d9e"
      nav-bg-color="#fff"
      nav-hover-bg-color="#eceffd"
      nav-hover-text-color="#185eeb"
      header-color="#fff"
      regular-font="sans-serif"
      mono-font="Roboto"
      nav-item-spacing="relaxed"
      allow-search="true"
      allow-advanced-search="true"
      default-schema-tab="schema"
      schema-description-expanded="true"
    >
      <div slot="overview"><Overview /></div>
      <img style="margin: 1em 2em" slot="logo" src="snyk-api.svg" />
      <div slot="header">
        <div v-if="versions.length === 0">Loading...</div>
        <VersionSwitch
          v-else
          :current-version="currentVersion"
          :versions="versions"
          @change="changeVersion"
        />
      </div>
    </rapi-doc>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import VersionSwitch from './VersionSwitch/VersionSwitch.vue';
import Overview from './Overview/Overview.vue';
import {
  loadVersions,
  setCurrentVersion,
  getAPIServerURL,
  watchUrlChange,
  getCurrentUrl,
  BASE_SPEC_URL,
} from './lib/side-effect-utils';
import { processSpec } from './lib/spec-processing';
import { createAnalytics } from './services/analytics';

interface State {
  versions: string[];
  currentVersion: string | null;
  serverUrl: string | null;
  specUrl: string | null;
}

const getVersion = (urlString: string): string | null => {
  const url = new URL(urlString);
  return url.searchParams.get('version');
};

const getPath = (urlString: string): string | null => {
  const url = new URL(urlString);
  return url.hash.substring(1);
};

export default defineComponent({
  name: 'App',
  components: {
    VersionSwitch,
    Overview,
  },

  data() {
    return {
      versions: [],
      currentVersion: getVersion(getCurrentUrl()),
      serverUrl: getAPIServerURL(),
      specUrl: null,
    } as State;
  },

  computed: {
    getSpecUrl(): string | null {
      return this.specUrl;
    },
  },

  methods: {
    changeVersion(version: string): void {
      setCurrentVersion(version);
      this.currentVersion = version;
      this.specUrl = `${BASE_SPEC_URL}/${version}`;
    },
  },

  async created(): Promise<void> {
    try {
      const analytics = await createAnalytics();
      const currentUrl = getCurrentUrl();

      analytics.page({
        url: currentUrl,
        version: getVersion(currentUrl),
        path: getPath(currentUrl),
      }); // Track current page.

      // Note: ideally we need to kill the timer on unmount, however, it's the main component and never get unmounted.
      watchUrlChange((url) =>
        analytics.page({
          url,
          version: getVersion(url),
          path: getPath(url),
        }),
      ); // Track on page change.
    } catch (err) {
      console.log('error loading segment', err);
    }
  },
  async mounted(): Promise<void> {
    try {
      const allVersions = await loadVersions();
      const currentUrl = new URL(getCurrentUrl());
      if (currentUrl.pathname === '/experimental') {
        this.versions = allVersions.filter((data) =>
          data.includes('experimental'),
        );
      } else {
        //Excluding all experiments after 2022-04-06 from main view
        const experimentalVersionDateCutOff = new Date('2022-04-06');
        this.versions = allVersions.filter((data) => {
          const [date, version] = data.split('~');
          const versionDate = new Date(date);
          return (
            version !== 'experimental' ||
            versionDate.toDateString() ===
              experimentalVersionDateCutOff.toDateString() ||
            versionDate < experimentalVersionDateCutOff
          );
        });
      }
    } catch (err) {
      //TODO: Display an error flash so we don't fail silently.
      console.error('Loading versions:', err);
    }

    const rd = document.getElementById('api-docs');
    rd?.addEventListener('before-render', (e) => {
      processSpec((e as CustomEvent).detail.spec, this.currentVersion);
    });

    if (this.currentVersion) {
      this.specUrl = `${BASE_SPEC_URL}/${this.currentVersion}`;
    } else {
      const latest = this.versions.findLast(
        (version) => !version.includes('~'),
      );
      if (latest == null) {
        this.changeVersion(this.versions[this.versions.length - 1]);
      } else {
        this.changeVersion(latest);
      }
    }
  },
});
</script>
