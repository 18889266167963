export interface RapiDocSpec {
  components: RapiDocComponents;
  schemas: Record<string, unknown>;
  info: Record<string, unknown>;
  openapi: string;
  paths: RapiDocSpecPath;
}
interface RapiDocSpecPath {
  [key: string]: RapiDocSpecOperation;
}
type RapiDocResponse = string | string[] | RapiDocSpecResponse;
interface RapiDocSpecOperation {
  get: RapiDocSpecResponse;
  [key: string]: RapiDocResponse;
}
interface RapiDocSpecResponse {
  responses: RapiDocSpecStatusCodes;
  summary: string;
  tags: string[];
  parameters?: RapiDocParameter[];
  deprecated?: boolean;
  // Snyk specific extensions
  'x-snyk-deprecated-by'?: string;
  'x-snyk-sunset-eligible'?: string;
  'x-snyk-api-version'?: string;
  // RapiDoc vendor extensions
  'x-badges'?: Badge[];
}
interface RapiDocSpecStatusCodes {
  [key: string]: {
    content: Record<string, unknown>;
    description: string;
    headers: Record<string, unknown>;
  };
}
interface RapiDocComponents {
  examples?: Record<string, RapiDocComponent>;
  headers?: Record<string, RapiDocComponent>;
  parameters?: Record<string, RapiDocComponent>;
  responses?: Record<string, RapiDocComponent>;
  schemas?: Record<string, RapiDocComponent>;
  securitySchemas?: Record<string, RapiDocComponent>;
}
interface RapiDocComponent {
  example?: string;
}
interface RapiDocParameter {
  in: string;
  name: string;
  example?: string;
}
interface OperationTransform {
  (response: RapiDocSpecResponse): void;
}

interface Badge {
  color: 'red' | 'green' | 'orange' | 'blue' | 'primary-color';
  label: string;
}

export const betaBadge: Badge = {
  color: 'blue',
  label: 'Beta',
};
export const experimentalBadge: Badge = {
  color: 'red',
  label: 'Experimental',
};
export const gaBadge: Badge = {
  color: 'primary-color',
  label: 'GA',
};

export const processSpec = (
  spec: RapiDocSpec,
  version: string | null,
): void => {
  // Most endpoints will use the common version component
  if (version != null && spec.components.parameters?.Version != null) {
    spec.components.parameters.Version.example = version;
  }

  applyOperationTransforms(
    spec,
    removeInternalHeaders,
    // We still need to update any inline version definitions
    setExampleVersion(version),
    addDeprecationTags,
    addStabilityBadge,
  );
};

const applyOperationTransforms = (
  spec: RapiDocSpec,
  ...transforms: OperationTransform[]
): void => {
  for (const path of Object.values(spec.paths)) {
    for (const operation of Object.values(path)) {
      if (!isSpecResponse(operation)) {
        continue;
      }
      for (const transform of transforms) {
        transform(operation);
      }
    }
  }
};

const removeInternalHeaders = (spec: RapiDocSpecResponse): void => {
  for (const resp of Object.values(spec.responses)) {
    for (const header of Object.keys(resp.headers)) {
      if (header.startsWith('x-envoy')) {
        delete resp.headers[header];
      }
    }
  }
};

export const setExampleVersion = (
  version: string | null,
): ((spec: RapiDocSpecResponse) => void) => {
  if (version == null) {
    return () => {};
  }

  return (spec: RapiDocSpecResponse): void => {
    const versionParam = spec.parameters?.find(
      (param) => param.in === 'query' && param.name === 'version',
    );
    if (versionParam != null) {
      versionParam.example = version;
    }
  };
};

const addDeprecationTags = (spec: RapiDocSpecResponse): void => {
  // Don't override values that have been explictly set
  if (spec.deprecated != null) {
    return;
  }
  const hasDeprecation = spec['x-snyk-deprecated-by'] != null;
  const hasSunsetPeriod = spec['x-snyk-sunset-eligible'] != null;
  spec.deprecated = hasDeprecation || hasSunsetPeriod;
};

const addStabilityBadge = (spec: RapiDocSpecResponse): void => {
  if (spec['x-snyk-api-version'] == null) {
    return;
  }
  spec['x-badges'] = [stabilityBadge(spec['x-snyk-api-version'])];
};

const isSpecResponse = (resp: RapiDocResponse): resp is RapiDocSpecResponse =>
  resp.constructor === Object;

const stabilityBadge = (apiVersion: string): Badge => {
  if (apiVersion.endsWith('experimental')) {
    return experimentalBadge;
  }
  if (apiVersion.endsWith('beta')) {
    return betaBadge;
  }
  return gaBadge;
};
