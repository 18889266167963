<template>
  <label
    for="v-switch"
    style="font-size: larger; font-family: Roboto, sans-serif"
    >Version:
  </label>
  <select
    id="version-switch"
    name="v-switch"
    @change="onChange"
    style="
      font-size: 16px;
      font-family: sans-serif;
      font-weight: 700;
      color: #444;
      line-height: 1.3;
      padding: 0.6em 1.4em 0.5em 0.8em;
      max-width: 100%; /* useful when width is set to anything other than 100% */
      box-sizing: border-box;
      margin: 0;
      border: 1px solid #aaa;
      box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
      border-radius: 0.5em;
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      background-color: #fff;
      font-size: large;
    "
  >
    <option
      v-for="version in versions"
      :key="version"
      :selected="currentVersion === version"
      :value="version"
    >
      {{ version }}
    </option>
  </select>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'VersionSwitch',

  props: {
    currentVersion: {
      type: String,
      default: null,
    },
    versions: {
      type: Array,
      default: null,
    },
  },

  methods: {
    onChange(evt: Event): void {
      this.$emit('change', (evt.target as HTMLInputElement).value);
    },
  },
});
</script>
