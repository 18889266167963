<template>
  <div id="overview">
    <div class="notice">
      <p>
        <img
          src="https://vectorified.com/images/info-icon-png-33.png"
          width="16px"
          alt=""
        />
        Thank you for your interest in our REST API docs. We are excited to
        announce that, as part of a major update to our API documentation, our
        REST API docs are being published alongside our user docs on
        <a href="https://docs.snyk.io/snyk-api"
          >https://docs.snyk.io/snyk-api</a
        >
      </p>
      <ul>
        <li>
          The REST Overview is now found on the
          <a href="https://docs.snyk.io/snyk-api/rest-api/about-the-rest-api"
            >About the Rest API</a
          >
          page
        </li>
        <li>
          API reference for REST APIs are in the new
          <a href="https://docs.snyk.io/snyk-api/reference">API reference</a>
          page
        </li>
      </ul>
      <p>
        <b
          >This site exists to provide information about older versions of our
          APIs. For all new use cases, we recommend following the links above to
          learn about the latest version.</b
        >
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({ name: 'Summary' });
</script>

<style>
#overview {
  margin-top: 2rem;
  max-width: 60rem;
}

.notice {
  background-color: rgba(172, 198, 238, 0.4);
  border: 1px solid rgb(172, 198, 238);
  font-family: 'helvetica neue', helvetica, sans-serif;
  padding: 1.5rem;
}

.notice > p {
  margin: 0;
  line-height: 1.6;
}

.notice > p + p {
  margin-top: 1rem;
}

.notice li {
  padding: 0.25rem 0;
}

#overview a {
  cursor: pointer;
  color: var(--blue);
}
</style>
